
export const environment = {
  production: true
};

export const Config = {
  api_key: 'bltd5108a60cd620ec1',
  delivery_token: 'cs4095c793604fac6301f0f16a',
  environment: 'development',
  region: 'us'
};